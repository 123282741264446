'use strict';
import * as angular from 'angular';
export var reportApp = angular.module('reportApp', [
  'angularjs.daterangepicker',
  'angularUtils.directives.dirPagination',
  'ngSanitize'])

reportApp.controller('reportCtrl', ['$scope', '$timeout', '$location', '$reportService', '$rootScope',
function ($scope, $timeout, $location, $reportService, $rootScope) {

  const quarterHours = [0, 15, 30, 45];
  $rootScope.startTime = moment().hours(2).minutes(0);
  $rootScope.showTime = $rootScope.startTime.clone().format('hh:mm A');

  $scope.url = rptConfig.url;

  $scope.submitOnLoad = rptConfig.submitOnLoad;
  $scope.errors = {};
  $scope.errorMsg = null;
  $scope.parameters = {};
  $scope.columns = [];
  $scope.results = null;
  $scope.inProgress = false;
  $scope.dateOptions = {
    startDate: $scope.startDate,
    endDate: $scope.endDate,
    ranges: {
      'Today': [moment.utc(), moment.utc()],
      'Yesterday': [moment.utc().subtract(1, 'days'), moment.utc().subtract(1, 'days')],
      'Last 7 Days': [moment.utc().subtract(6, 'days'), moment.utc()],
      'Last 30 Days': [moment.utc().subtract(29, 'days'), moment.utc()],
      'This Month': [moment.utc().startOf('month'), moment.utc().endOf('month')],
      'Last Month': [moment.utc().subtract(1, 'month').startOf('month'), moment.utc().subtract(1, 'month').endOf('month')]
    },
    opens: 'right',
    showCustomRangeLabel: true,
    timeZone: 'utc'
  };


  $rootScope.tempTime = moment().hours(2).minutes(0);
  $rootScope.submitTime = (newTime) => {
    let oldMinutes = $rootScope.tempTime.minutes();
    let oldHours = $rootScope.tempTime.hours();
    let time = moment(newTime).seconds(0).milliseconds(0);
    let minutes = time.clone().minutes();
    let moduloDiv = minutes % 15;
    let currentIndex = Math.floor(minutes / 15);


    time = moduloDiv >= 7 ? time.clone().minutes(quarterHours[currentIndex + 1]) : time.clone().minutes(quarterHours[currentIndex]);

    // Hours should not update when minutes are being changed
    if (oldMinutes != minutes) {
      time = time.clone().hours(oldHours);
    }

    $rootScope.tempTime = moment(time).seconds(0).milliseconds(0);
    $rootScope.startTime = moment(time).seconds(0).milliseconds(0);

  }

  /**
   * This actually changes the time, visually. This is activated by the save button and transfers the state into the showTime variable, which is displayed
   */
  $rootScope.changeTime = () => {
    $rootScope.startTime = moment($rootScope.startTime).seconds(0).milliseconds(0);

    $rootScope.showTime = $rootScope.startTime.format('hh:mm A')
    $rootScope.startTime = moment($rootScope.startTime).seconds(0).milliseconds(0);
  }

  $scope.singleDateOptions = {
    singleDatePicker: true
  };
  var formatDownload = function (results) {
    var result = results.map(function (row) {
      var newRow = {}
      for (let field in row) {
        if (row.hasOwnProperty(field)) {
          newRow[field] = (row[field] || '').toString();
        }
      }


      return newRow;
    });


    return result;
  }



  var formatTable = function (results) {
    $scope.columns = [];
    $scope.results = results;
    if (results.length === 0) {
      return;
    }
    var first = results[0];
    for (var col in first) {

      if (!first.hasOwnProperty(col)) continue;

      $scope.columns.push({
        name: col.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }),
        field: col
      });
    }

    $scope.resultDownload = formatDownload(results);

  };

  $scope.getColNames = function (columns) {
    if (!columns) {
      return [];
    }
    return columns.map(c => c.name);
  }

  $rootScope.showBatchSync = false;
  $rootScope.toggleBatchSync = function () {
    $rootScope.showBatchSync = !$rootScope.showBatchSync;
  };

  $rootScope.tableToCsv = function () {
    $reportService.getData($scope.url, $scope.parameters, $rootScope.startTime.clone().format('HH:mm'), $rootScope.showBatchSync, function (response) {
      $scope.inProgress = false;

      if (response.wasSuccessful) {

        jsonToCsv(response.content);

      } else {

        if (response.statusCode === 403) {
          response.message = 'You do not have privileges to run this report.';
        }
        $scope.errorMsg = response.message;
        $scope.fieldErrors = response.errors;
        $timeout(function () {
          $scope.errorMsg = null;
        }, 5000)

      }
      $location.search($scope.parameters);
    });

  }
  const jsonToCsv = (jsonData) => {


    // Parse the JSON string if it's not already an object
    const data = typeof jsonData === 'string' ? JSON.parse(jsonData) : jsonData;


    // Ensure data is an array
    if (!Array.isArray(data)) {

      return;
    }

    // Dynamically determine headers from the first object in the array
    const headers = Object.keys(data[0]);

    // Create the CSV content
    let csvContent = headers.join(',') + '\r\n';

    // Add data rows
    data.forEach(item => {
      const row = headers.map(header => {
        // Wrap the value in quotes if it contains a comma
        let value = item[header];
        if (value === null || value === undefined) {
          value = '';
        } else if (typeof value === 'string' && value.includes(',')) {
          value = `"${value}"`;
        }
        return value;
      });
      csvContent += row.join(',') + '\r\n';
    });

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a download link
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  $rootScope.submitParameters = function () {

    if($scope.inProgress){
      return;
    }

    $scope.successMsg = null;
    $scope.errorMsg = null;
    $scope.fieldErrors = {};
    $scope.inProgress = true;

    $reportService.getData($scope.url, $scope.parameters, $rootScope.startTime.clone().format('HH:mm'), $rootScope.showBatchSync, function (response) {

      $scope.inProgress = false;
      if (response.wasSuccessful) {
        formatTable(response.content);
        $scope.successMsg = "Retrieved " + response.content.length + " rows.";
        $timeout(function () {
          $scope.successMsg = null;
        }, 5000)

      } else {
        if (response.statusCode === 403) {
          response.message = 'You do not have privileges to run this report.';
        }
        $scope.errorMsg = response.message;
        $scope.fieldErrors = response.errors;
        $timeout(function () {
          $scope.errorMsg = null;
        }, 5000)

      }
      $location.search($scope.parameters);
    });

  };


  //initializing off of querystring
  if (Object.keys($location.search()).length > 0 || $scope.submitOnLoad) {
    $scope.parameters = $location.search();
    for (let field in $scope.parameters) {
      if (!$scope.parameters.hasOwnProperty(field)) {
        continue;
      }
      if (field.toLowerCase().indexOf('date') > 0) {
        $scope.parameters[field] = moment($scope.parameters[field]);
      }
    }

    $rootScope.submitParameters();
  }
}]);
